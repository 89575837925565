import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';
import SideNav, {NavItem, NavIcon, NavText} from '@trendmicro/react-sidenav';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Dashboard} from "./pages/Dashboard";
import {Login} from "./pages/Login";
import {
  faHome,
  faUser,
  faLayerGroup,
  faServer,
  faSignOutAlt,
  faTachometerAlt,
  faTools
} from "@fortawesome/free-solid-svg-icons";
import {Users} from "./pages/admin/Users";
import {Home} from "./pages/admin/Home";
import {Logout} from "./pages/Logout";
import {Groups} from "./pages/admin/Groups";
import {Services} from "./pages/admin/Services";
import {Reports} from "./pages/admin/Reports";
import {IncidentsHistory} from "./pages/IncidentsHistory";
import {Report} from "./pages/Report";

const cleanPath = (pathname) => {
  if (pathname === '/admin') return '/';
  return pathname.replace('/admin/', '');
}

const setPath = (pathname) => {
  if (pathname === '/') return '';
  return pathname;
}

class App extends Component {
  render() {
    const App = () => (
        <div>
          <Switch>
            <Route path='/history' component={IncidentsHistory}/>
            <Route exact path='/reports/:id' component={Report}/>
            <Route exact path='/login' component={Login}/>
            <Route exact path='/logout' component={Logout}/>
            {localStorage.getItem('authToken') && <Route path='/admin' render={({match: {url}, history, location}) => (
                <div>
                  <SideNav onSelect={(selected) => history.push(`/admin/${setPath(selected)}`)}
                           style={{background: "#1e4276"}}>
                    <SideNav.Toggle/>
                    <SideNav.Nav defaultSelected={cleanPath(location.pathname)}>
                      <NavItem eventKey="/">
                        <NavIcon><FontAwesomeIcon icon={faHome} style={{color: 'white'}}/></NavIcon>
                        <NavText style={{color: 'white'}}>Home</NavText>
                      </NavItem>
                      <NavItem eventKey="dashboard">
                        <NavIcon><FontAwesomeIcon icon={faTachometerAlt} style={{color: 'white'}}/></NavIcon>
                        <NavText style={{color: 'white'}}>Dashboard</NavText>
                      </NavItem>
                      <NavItem eventKey="users">
                        <NavIcon><FontAwesomeIcon icon={faUser} style={{color: 'white'}}/></NavIcon>
                        <NavText style={{color: 'white'}}>Users</NavText>
                      </NavItem>
                      <NavItem eventKey="groups">
                        <NavIcon><FontAwesomeIcon icon={faLayerGroup} style={{color: 'white'}}/></NavIcon>
                        <NavText style={{color: 'white'}}>Services Groups</NavText>
                      </NavItem>
                      <NavItem eventKey="services">
                        <NavIcon><FontAwesomeIcon icon={faServer} style={{color: 'white'}}/></NavIcon>
                        <NavText style={{color: 'white'}}>Services</NavText>
                      </NavItem>
                      <NavItem eventKey="reports">
                        <NavIcon><FontAwesomeIcon icon={faTools} style={{color: 'white'}}/></NavIcon>
                        <NavText style={{color: 'white'}}>Reports</NavText>
                      </NavItem>
                      <NavItem eventKey="logout">
                        <NavIcon><FontAwesomeIcon icon={faSignOutAlt} style={{color: 'white'}}/></NavIcon>
                        <NavText style={{color: 'white'}}>Logout</NavText>
                      </NavItem>
                    </SideNav.Nav>
                  </SideNav>
                  <div style={{paddingLeft: 65, overflow: 'scroll'}} className={"vh-100"}>
                    <Route path={`${url}/`} component={Home} exact/>
                    <Route path={`${url}/dashboard`}><Dashboard hide={true}/></Route>
                    <Route path={`${url}/users`} component={Users}/>
                    <Route path={`${url}/groups`} component={Groups}/>
                    <Route path={`${url}/services`} component={Services}/>
                    <Route path={`${url}/reports`} component={Reports} exact/>
                    <Route path={`${url}/reports/:id`} component={Reports}/>
                    <Route path={`${url}/logout`} component={Logout}/>
                  </div>
                </div>
            )}/>}
            <Route exact path='*' component={Dashboard}/>
          </Switch>
        </div>
    )
    return (
        <Switch>
          <App/>
        </Switch>
    );
  }
}

export default App;