import React, {Component} from 'react';
import {Badge, Button, ButtonGroup, Col, Row} from "react-bootstrap";
import DataTable from "react-data-table-component";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfo, faTrash} from "@fortawesome/free-solid-svg-icons";
import {ModalForm} from "./Form";
import {Status} from "./Status";
import {Impact} from "./Impact";

export class List extends Component {
  constructor(props) {
    super(props);

    this.tableActions = this.tableActions.bind(this);
    this.newReport = this.newReport.bind(this);
    this.deleteReport = this.deleteReport.bind(this);

    this.state = {
      reports: [],
      modal: null
    };
  }

  async componentDidMount() {
    await this.loadReports()
  }

  async loadReports() {
    const request = await fetch('/api/reports', {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      }
    });

    if (request.status !== 200) return this.props.history.push('/admin/error');
    this.setState({reports: await request.json()})
  }

  tableServices(row) {
    if (!row.services.length) return <Badge pill variant={"secondary"}>No Services</Badge>;
    return <div style={{textAlign: "center", position: "relative", padding: 10}}>
      {row.services.map(service => <><Badge pill variant={"primary"}>{service.name}</Badge><br/></>)}
    </div>
  }

  tableActions(row) {
    return <ButtonGroup aria-label="Actions">
      <Button variant="primary" onClick={() => this.props.history.push(`/admin/reports/${row.id}`)}
              style={{paddingLeft: 15, paddingRight: 15}}>
        <FontAwesomeIcon icon={faInfo} style={{color: 'white'}}/>
      </Button>
      <Button variant="danger" onClick={() => this.deleteReport(row)}>
        <FontAwesomeIcon icon={faTrash} style={{color: 'white'}}/>
      </Button>
    </ButtonGroup>
  }

  async newReport() {
    const add = async (fields) => {
      const resp = await fetch('/api/reports', {
        method: 'POST',
        body: JSON.stringify(fields),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
      })

      if (resp.status !== 201) return;
      this.setState({modal: null});
      await this.loadReports();
    }
    this.setState({
      modal: <ModalForm title={"Create new Report"} action={add} close={() => this.setState({modal: null})}/>
    })
  }

  deleteReport(service) {
    const remove = async () => {
      await fetch(`/api/reports/${service.id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
      })

      this.setState({modal: null});
      await this.loadReports();
    }

    this.setState({
      modal: <ModalForm close={() => this.setState({modal: null})} actionType={"danger"} title={"Delete service"}
                        service={service} disabled={true} action={remove}/>
    })
  }

  render() {
    const columns = [
      {
        name: 'Name',
        selector: 'name',
        sortable: true,
        minWidth: "20%"
      }, {
        name: 'Status',
        selector: 'status',
        cell: row => <Status status={row.status}/>
      }, {
        name: 'Impact',
        selector: 'impact',
        cell: row => <Impact impact={row.impact}/>
      }, {
        name: 'Services',
        cell: this.tableServices,
        style: {display: "flex"},
        minWidth: "15%"
      }, {
        name: 'Updated At',
        selector: 'updatedAt',
        sortable: true,
        minWidth: "20%"
      }, {
        name: 'Updated At',
        selector: 'updatedAt',
        sortable: true,
        minWidth: "20%",
      }, {
        title: 'Actions',
        cell: this.tableActions,
        ignoreRowClick: true,
        button: false,
        minWidth: "15%"
      },
    ]

    return (
        <div className={"container-fluid"}>
          {this.state.modal}
          <Row style={{paddingTop: 10}}>
            <Col xl={8}>
              <h1>Reports List</h1>
            </Col>
            <Col xl={4}>
              <h1 style={{textAlign: 'right'}}><Button variant="primary" onClick={this.newReport}>Add Report</Button>
              </h1>
            </Col>
          </Row>
          <DataTable data={this.state.reports} columns={columns}/>
        </div>
    );
  }
}