import React, {Component} from 'react';
import {Alert, Button} from 'react-bootstrap';
import {MainHeader} from "../components/MainHeader";
import {Group} from "../components/Group";
import {Blank} from "../components/Blank";
import {BeatLoader} from "react-spinners";
import {faSync} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {MainFooter} from "../components/MainFooter";
import {PastIncidents} from "../components/PastIncidents";
import {ActiveIncidents} from "../components/ActiveIncidents";

export class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      groups: null,
      status: null,
      groupsDraw: null
    }
  }

  // Fetch the list on first mount
  componentDidMount() {
    this.getGroups().then();
  }

  // Retrieves the list of items from the Express app
  async getGroups() {
    this.setState({groups: null, drawGroups: null, status: null})
    const req = await fetch('/api/groups', {
      headers: {
        ...(localStorage.getItem('authToken') && {'Authorization': `Bearer ${localStorage.getItem('authToken')}`})
      }
    });
    if (req.status === 401) return this.props.history.push('/logout')
    this.setState({groups: await req.json()})
  }

  setGroupStatus(status) {
    this.setState({status: Math.max(this.state.status, status)})
  }

  drawGroups() {
    if (!this.state.groups) return
    if (this.state.drawGroups) return this.state.drawGroups;


    const drawGroups = <>
      <Blank height={10}/>
      {this.state.groups.map(group => {
        return (
            <div>
              <Group {...group} setGroupStatus={this.setGroupStatus.bind(this)}/>
              <Blank height={20}/>
            </div>
        )
      })}
    </>

    this.setState({drawGroups})
    return drawGroups;
  }

  statusAlert() {
    const res = this.state.status && Math.ceil(this.state.status)
    switch (res) {
      case 2:
        return <Alert variant={"warning"}>Our Systems are experiencing a Degraded Performance</Alert>
      case 3:
        return <Alert variant={"danger"}>Our Systems are experiencing a Partial outage</Alert>
      case 4:
        return <Alert variant={"dark"}>Our Systems are experiencing a Major Outage</Alert>
      case 5:
        return <Alert variant={"info"}>Our Systems are Under Maintenance</Alert>
      case null:
        return <Alert variant={"primary"}>Loading Systems</Alert>
      default:
        return <Alert variant={"success"}>All Systems Operational</Alert>
    }
  }

  render() {
    return (
        <div className={"container"}>
          {!this.props.hide && <MainHeader history={this.props.history}/>}
          <Blank height={50}/>
          {this.statusAlert()}
          <Blank height={20}/>
          <div className={"row"}>
            <div className={"col-8"} style={{textAlign: 'left'}}><h2><strong>Our Systems</strong></h2></div>
            <div className={"col-4"} style={{textAlign: 'right', cursor: 'pointer'}} onClick={() => this.getGroups()}>
              <h2><strong><FontAwesomeIcon icon={faSync}/></strong></h2>
            </div>
          </div>
          {this.drawGroups()}
          {this.state.drawGroups && !this.state.groups.length &&
              <h4 style={{textAlign: 'center'}}>No services has been defined</h4>}
          {this.state.drawGroups === null && <div style={{textAlign: 'center', padding: 20}}>
            <BeatLoader color={"#2a4982"} size={20}/>
          </div>}
          <Blank height={20}/>
          <div className={"row"}>
            <div className={"col-8"} style={{textAlign: 'left'}}><h2><strong>Active Incidents</strong></h2></div>
            <div className={"col-4"}/>
          </div>
          <Blank height={10}/>
          <ActiveIncidents history={this.props.history}/>
          <Blank height={20}/>
          <div className={"row"}>
            <div className={"col-8"} style={{textAlign: 'left'}}><h2><strong>Past Incidents</strong></h2></div>
            <div className={"col-4"}/>
          </div>
          <Blank height={10}/>
          <PastIncidents history={this.props.history}/>
          <a style={{cursor: 'pointer'}} onClick={() => this.props.history.push('/history')}>&larr; Incident History</a>

          {!this.props.hide && <MainFooter/>}
        </div>
    );
  }
}