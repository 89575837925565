import React, {Component} from 'react';
import {Accordion, Card, Badge, useAccordionToggle} from 'react-bootstrap';
import {Service, serviceStatus} from "./Service";
import {Blank} from "./Blank";
import {BeatLoader} from "react-spinners";


export class Group extends Component {
  constructor(props) {
    super(props);
    this.state = {services: null}
  }

  async componentDidMount() {
    this.setState({services: null})
    const res = await fetch(`/api/groups/${this.props.id}/services`, {
      headers: {
        ...(localStorage.getItem('authToken') && {'Authorization': `Bearer ${localStorage.getItem('authToken')}`})
      }
    });
    this.setState({services: await res.json()})
  }

  statusBadge() {
    if (!this.state.services)
      return (<Badge pill variant={"secondary"}>Loading...</Badge>)

    if (!this.state.services.length)
      return (<Badge pill variant={"secondary"}>No Services</Badge>)

    let avgState = -1;
    this.state.services.forEach(service => {
      avgState = Math.max(avgState, (service.forcedStatus || service.status))
    })
    this.props.setGroupStatus(Math.ceil(avgState));
    return serviceStatus(Math.ceil(avgState));
  }

  render() {
    let blank = false;
    const CustomToggle = ({eventKey}) => {
      const decoratedOnClick = useAccordionToggle(eventKey, () =>
          console.log('totally custom!'),
      );
      return (<div className={"row align-items-center"} onClick={decoratedOnClick} style={{cursor: 'pointer'}}>
        <div className={"col-6"} style={{textAlign: "left"}}>
          <h5 style={{margin: 0}}><strong>{this.props.name}</strong></h5>
        </div>
        <div className={"col-6"} style={{textAlign: "right"}}>{this.statusBadge()}</div>
      </div>);
    }

    return (
        <Accordion className={"Group"} {...(!this.props.coalesced && {defaultActiveKey: "0"})}>
          <Card>
            <Card.Header>
              <CustomToggle eventKey="0"/>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                {this.state.services && this.state.services.map(service => {
                  return (<div>
                    {blank && <Blank height={10}/>}
                    {blank = true}
                    <Service {...service}/>
                  </div>)
                })}
                {this.state.services && !this.state.services.length &&
                    <h6 style={{textAlign: 'center'}}>No services has been defined</h6>}
                {this.state.services === null && <div style={{textAlign: 'center', padding: 20}}>
                  <BeatLoader color={"#2a4982"} size={10}/>
                </div>}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
    );
  }
}