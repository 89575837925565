import React, {Component} from 'react';
import {Badge} from 'react-bootstrap';

export function serviceStatus(status) {
  switch (status) {
    case 1:
      return (<Badge pill variant={"success"}>Operational</Badge>)
    case 2:
      return (<Badge pill variant={"warning"}>Degraded performance</Badge>)
    case 3:
      return (<Badge pill variant={"danger"}>Partial outage</Badge>)
    case 4:
      return (<Badge pill variant={"dark"}>Major outage</Badge>)
    case 5:
      return (<Badge pill variant={"info"}>Under maintenance</Badge>)
    case null:
      return <Badge pill variant={"light"}>Disabled</Badge>
    default:
      return (<Badge pill variant={"secondary"}>Unknown</Badge>)
  }
}

export const statusList = [
  {id: null, name: 'Disabled'},
  {id: 0, name: "Unknown"},
  {id: 1, name: "Operational"},
  {id: 2, name: "Degraded performance"},
  {id: 3, name: "Partial outage"},
  {id: 4, name: "Major outage"},
  {id: 5, name: "Under maintenance"},
]

export class Service extends Component {
  constructor(props) {
    super(props);
    this.state = {services: []}
  }

  render() {
    return (
        <div className={"row align-items-center Service"}>
          <div className={"col-6"} style={{textAlign: "left"}}>
            <h6 style={{margin: 0}}>{this.props.name}</h6>
          </div>
          <div className={"col-6"}
               style={{textAlign: "right"}}>{serviceStatus(this.props.forcedStatus || this.props.status)}</div>
        </div>
    );
  }
}