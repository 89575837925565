import React, {Component} from 'react';

export class Home extends Component {
  render() {
    return (
        <div className="container h-100">
          <div className="row align-items-center h-100">
            <div className="col-6 mx-auto">
              <div className="h-100 justify-content-center">
                <div style={{textAlign: 'center'}}>
                  <h3><img src={"https://static.ivao.aero/img/logos/logo.svg"} style={{height: 150}}/></h3>
                  <h4>IVAO Heartbeat admin panel</h4>
                  <h6>Use the menu to navigate through the different options.</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
  }
}