import React, {Component} from 'react';
import {Button, ButtonGroup, Col, Form, Modal, Row} from "react-bootstrap";
import DataTable from "react-data-table-component";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faTrash} from "@fortawesome/free-solid-svg-icons";
import {ModalForm} from "./Form";
import {Status, statusList} from "./Status";
import Select from "react-select";
import {statusList as serviceStatusList} from "../Service";


export class Events extends Component {
  constructor(props) {
    super(props);

    this.tableActions = this.tableActions.bind(this);
    this.newEvent = this.newEvent.bind(this);
    this.deleteEvent = this.deleteEvent.bind(this);
    this.editEvent = this.editEvent.bind(this);

    this.state = {
      modal: null
    };
  }

  async componentDidMount() {
    await this.loadReports()
  }

  async loadReports() {
    const request = await fetch('/api/reports', {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      }
    });

    if (request.status !== 200) return this.props.history.push('/admin/error');
    this.setState({reports: await request.json()})
  }

  tableActions(row) {
    return <ButtonGroup aria-label="Actions">
      <Button variant="primary" onClick={() => this.editEvent(row)}
              style={{paddingLeft: 15, paddingRight: 15}}>
        <FontAwesomeIcon icon={faEdit} style={{color: 'white'}}/>
      </Button>
      <Button variant="danger" onClick={() => this.deleteEvent(row)}>
        <FontAwesomeIcon icon={faTrash} style={{color: 'white'}}/>
      </Button>
    </ButtonGroup>
  }

  async newEvent() {
    const add = async (fields) => {
      const resp = await fetch(`/api/reports/${this.props.reportId}/events`, {
        method: 'POST',
        body: JSON.stringify(fields),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
      })

      if (resp.status !== 201) return;
      this.setState({modal: null});
      this.props.reload();
    }
    this.setState({
      modal: <EventForm title={"Create new event"} action={add} close={() => this.setState({modal: null})}/>
    })
  }

  editEvent(event) {
    const edit = async (fields) => {
      const resp = await fetch(`/api/reports/${this.props.reportId}/events/${event.id}`, {
        method: 'PUT',
        body: JSON.stringify(fields),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
      })

      if (resp.status !== 200) return;
      this.setState({modal: null});
      this.props.reload();
    }
    this.setState({
      modal: <EventForm title={"Edit Report"} action={edit} event={event} close={() => this.setState({modal: null})}/>
    })
  }

  deleteEvent(event) {
    const remove = async () => {
      await fetch(`/api/reports/${this.props.reportId}/events/${event.id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
      })

      this.setState({modal: null});
      this.props.reload();
    }

    this.setState({
      modal: <ModalForm close={() => this.setState({modal: null})} actionType={"danger"} title={"Delete service"}
                        service={event} disabled={true} action={remove}/>
    })
  }

  render() {
    const columns = [
      {
        name: 'Status',
        selector: 'status',
        cell: row => <Status status={row.status}/>
      }, {
        name: 'Message',
        selector: 'message',
      }, {
        name: 'Updated At',
        selector: 'updatedAt',
      }, {
        title: 'Actions',
        cell: this.tableActions,
        ignoreRowClick: true,
        button: false,
        minWidth: "15%"
      },
    ]

    return (
        <div className={"container-fluid"}>
          {this.state.modal}
          <Row style={{paddingTop: 10}}>
            <Col xl={8}/>
            <Col xl={4}>
              <h4 style={{textAlign: 'right'}}><Button variant="primary" onClick={this.newEvent}>Add Events</Button>
              </h4>
            </Col>
          </Row>
          <DataTable data={this.props.events} columns={columns}/>
        </div>
    );
  }
}


class EventForm extends Component {
  constructor(props) {
    super(props);

    this.actionHandler = this.actionHandler.bind(this);

    this.state = {
      services: [],
      fields: {}
    }
  }

  actionHandler(e) {
    e.preventDefault();
    this.props.action(this.state.fields);
  }

  render() {
    const statusSelected = statusList.filter(({id}) => id === (this.props.event ? this.props.event.status : 0))

    return (<Modal show centered size="lg" onHide={this.props.close}>
      <Form onSubmit={this.actionHandler}>
        <Modal.Header closeButton>
          <Modal.Title>{this.props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row style={{paddingTop: 10}}>
            <Col xl={6}>
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label>Status</Form.Label>
                {statusSelected.length &&
                    <Select options={statusList}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            defaultValue={statusSelected[0]}
                            isDisabled={this.props.disabled}
                            isOptionDisabled={(option) => option.disabled}
                            onChange={(e) => this.state.fields.status = e.id}/>}
              </Form.Group>
            </Col>
            <Col xl={6}>
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label>Forced Service Status</Form.Label>
                <Select options={[{id: undefined, name: 'Keep Actual'}].concat(serviceStatusList)}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        isDisabled={this.props.disabled}
                        defaultValue={{id: undefined, name: 'Keep Actual'}}
                        onChange={(e) => this.state.fields.forcedStatus = e.id}/>
              </Form.Group>
            </Col>
          </Row>
          <Row style={{paddingTop: 10}}>
            <Col xl={12}>
              <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Label>Message</Form.Label>
                <Form.Control as="textarea" rows={3} disabled={this.props.disabled}
                              defaultValue={this.props.event && this.props.event.message}
                              onChange={(e) => this.state.fields.message = e.target.value}/>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.closeHandler}>Close</Button>
          <Button type={"submit"} variant={this.props.actionType}>{this.props.title}</Button>
        </Modal.Footer>
      </Form>
    </Modal>);
  }
}