import React, {Component} from 'react';

export class Login extends Component {
  constructor(props) {
    super(props);
    this.doLogin = this.doLogin.bind(this);
    this.state = {
      errors: {},
      mail: null,
      password: null
    }
  }

  async doLogin(e) {
    e.preventDefault();
    const res = await fetch(`/api/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({mail: this.state.mail, password: this.state.password})
    });
    const data = await res.json();

    switch (res.status) {
      case 200:
        localStorage.setItem('authToken', data.token);
        return this.props.history.push('/admin')
      case 400:
        return this.setState({errors: data.error})
      default:
        return this.setState({errors: {mail: "Invalid mail", password: "Invalid password"}})


    }
  }

  render() {
    return (
        <div className="auth-wrapper">
          <div className="auth-inner">
            <form onSubmit={this.doLogin}>
              <h3><img src={"https://static.ivao.aero/img/logos/logo.svg"} style={{height: 110}}/></h3>
              <div className="form-group">
                <label>Email address</label>
                <input type="email" className="form-control" placeholder="Enter email"
                       value={this.mail} required={true}
                       onChange={(e) => this.setState({mail: e.target.value})}/>
                {this.state.errors.mail &&
                    <p style={{color: "red", paddingTop: 3, paddingLeft: 10}}>{this.state.errors.mail}</p>}
              </div>
              <div className="form-group">
                <label>Password</label>
                <input type="password" className="form-control" placeholder="Enter password"
                       value={this.password} required={true}
                       onChange={(e) => this.setState({password: e.target.value})}/>
                {this.state.errors.password &&
                    <p style={{color: "red", paddingTop: 3, paddingLeft: 10}}>{this.state.errors.password}</p>}
              </div>
              <br/>
              <button type="submit" className="btn btn-primary btn-block">Submit</button>
            </form>
          </div>
        </div>
    );
  }
}