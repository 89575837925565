import React, {Component} from 'react';
import {Badge, Button, ButtonGroup, Col, Dropdown, DropdownButton, Nav, Row, Tab} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faTools, faTrash} from "@fortawesome/free-solid-svg-icons";
import {ModalForm} from "./Form";
import {Status} from "./Status";
import {Events} from "./Events";
import {Impact} from "./Impact";
import {statusList as serviceStatusList} from "../Service";

export class Show extends Component {
  constructor(props) {
    super(props);

    this.deleteReport = this.deleteReport.bind(this)

    this.state = {
      data: {},
      modal: null
    };
  }

  async componentDidMount() {
    const request = await fetch(`/api/reports/${this.props.match.params.id}`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      }
    });

    if (request.status !== 200) return this.props.history.push('/admin/error');
    this.setState({data: await request.json()})
  }

  editReport() {
    const edit = async (fields) => {
      const resp = await fetch(`/api/reports/${this.state.data.id}`, {
        method: 'PUT',
        body: JSON.stringify(fields),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
      })

      if (resp.status !== 200) return;
      this.setState({modal: null});
      await this.componentDidMount();
    }

    this.setState({
      modal: <ModalForm close={() => this.setState({modal: null})} actionType={"primary"} title={"Edit Report"}
                        report={this.state.data} action={edit}/>
    })
  }

  tableServices() {
    if (!this.state.data.services) return
    if (!this.state.data.services.length) return <Badge pill variant={"secondary"}>No Services</Badge>;
    return <div>
      {this.state.data.services.map(service => <><Badge pill variant={"primary"}>{service.name}</Badge><br/></>)}
    </div>
  }

  deleteReport() {
    const me = this;
    const remove = async () => {
      await fetch(`/api/reports/${me.state.data.id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
      })

      this.setState({modal: null})
      me.props.history.push('/admin/reports');
    }

    this.setState({
      modal: <ModalForm close={() => this.setState({modal: null})} actionType={"danger"} title={"Delete service"}
                        report={this.state.data} disabled={true} action={remove}/>
    })
  }

  render() {
    return (
        <div className={"container-fluid"}>
          {this.state.modal}
          <Row style={{paddingTop: 10}}>
            <Col xl={4} style={{textAlign: 'left', color: 'gray'}}>
              <h5 style={{cursor: 'pointer'}} onClick={() => this.props.history.push('/admin/reports')}>
                &lt; Reports List
              </h5>
            </Col>
            <Col xl={4} style={{textAlign: 'center'}}>
              {this.state.data != null && <h3>{this.state.data.name}</h3>}
            </Col>
            <Col xl={4}>
              <h1 style={{textAlign: 'right'}}>
                <ButtonGroup aria-label="Actions">
                  <Button variant="primary" onClick={() => this.editReport()}>
                    <FontAwesomeIcon icon={faEdit} style={{color: 'white'}}/>
                  </Button>
                  <Button variant="danger" onClick={() => this.deleteReport()}>
                    <FontAwesomeIcon icon={faTrash} style={{color: 'white'}}/>
                  </Button>
                </ButtonGroup>
              </h1>
            </Col>
          </Row>
          <Tab.Container id="left-tabs-example" defaultActiveKey="info">
            <Row>
              <Col sm={3}>
                <Nav variant="pills" className="flex-column">
                  <Nav.Item>
                    <Nav.Link eventKey="info">Info</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="events">Events</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col sm={9}>
                <Tab.Content>
                  <Tab.Pane eventKey="info">
                    <table>
                      <tr>
                        <th style={{textAlign: 'right'}}>Name:</th>
                        <td style={{paddingLeft: 10}}>{this.state.data.name}</td>
                      </tr>
                      <tr>
                        <th style={{textAlign: 'right'}}>Status:</th>
                        <td style={{paddingLeft: 10}}><Status status={this.state.data.status}/></td>
                      </tr>
                      <tr>
                        <th style={{textAlign: 'right'}}>Scheduled At:</th>
                        <td style={{paddingLeft: 10}}>{this.state.data.scheduledAt || "--"}</td>
                      </tr>
                      <tr>
                        <th style={{textAlign: 'right'}}>Impact:</th>
                        <td style={{paddingLeft: 10}}><Impact status={this.state.data.impact}/></td>
                      </tr>
                      <tr>
                        <th style={{textAlign: 'right'}}>Services:</th>
                        <td style={{paddingLeft: 10}}>{this.tableServices()}</td>
                      </tr>
                      <tr>
                        <th style={{textAlign: 'right'}}>Description:</th>
                        <td style={{paddingLeft: 10}} ><span className={'new-line'}>{this.state.data.description || "--"}</span></td>
                      </tr>
                      <tr>
                        <th style={{textAlign: 'right'}}>Postmortem:</th>
                        <td style={{paddingLeft: 10}}>{this.state.data.postmortem || "--"}</td>
                      </tr>
                    </table>
                  </Tab.Pane>
                  <Tab.Pane eventKey="events">
                    <Events events={this.state.data.events} reportId={this.state.data.id}
                            reload={this.componentDidMount.bind(this)}/>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
    );
  }
}