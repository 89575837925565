import React, {Component} from 'react';
import {
  Button,
  Col,
  Modal,
  Row,
  Form
} from "react-bootstrap";
import {statusList} from "./Status";
import {impactList} from "./Impact";
import Select from 'react-select'
import Datetime from 'react-datetime';
import {statusList as serviceStatusList} from "../Service";

export class ModalForm extends Component {
  constructor(props) {
    super(props);

    this.actionHandler = this.actionHandler.bind(this);

    this.state = {
      services: [],
      fields: {}
    }
  }

  async componentDidMount() {
    const request = await fetch('/api/services', {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      }
    });

    if (request.status !== 200) return this.props.history.push('/admin/error');
    this.setState({services: await request.json()})
  }

  actionHandler(e) {
    e.preventDefault();
    console.log(this.state.fields)
    this.props.action(this.state.fields);
  }

  render() {
    const statusSelected = statusList.filter(({id}) => id === (this.props.report && this.props.report.status || 0))
    const impactSelected = impactList.filter(({id}) => id === (this.props.report && this.props.report.impact || 0))

    return (<Modal show centered size="lg" onHide={this.props.close}>
      <Form onSubmit={this.actionHandler}>
        <Modal.Header closeButton>
          <Modal.Title>{this.props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row style={{paddingTop: 10}}>
            <Col xl={12}>
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label>Name</Form.Label>
                <Form.Control type="text" placeholder="Report Name"
                              defaultValue={this.props.report && this.props.report.name}
                              disabled={this.props.disabled} required
                              onChange={(e) => this.state.fields.name = e.target.value}/>
              </Form.Group>
            </Col>
          </Row>
          <Row style={{paddingTop: 10}}>
            <Col xl={6}>
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label>Status</Form.Label>
                {statusSelected.length &&
                    <Select options={statusList}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            defaultValue={statusSelected[0]}
                            isDisabled={this.props.disabled}
                            isOptionDisabled={(option) => option.disabled}
                            onChange={(e) => this.state.fields.status = e.id}/>}
              </Form.Group>
            </Col>
            <Col xl={6}>
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label>Impact</Form.Label>
                {impactSelected.length &&
                    <Select options={impactList}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            defaultValue={impactSelected[0]}
                            isDisabled={this.props.disabled}
                            onChange={(e) => this.state.fields.impact = e.id}/>}
              </Form.Group>
            </Col>
          </Row>
          <Row style={{paddingTop: 10}}>
            <Col xl={6}>
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label>Scheduled At</Form.Label>
                <Datetime utc={true} onChange={(e) => this.state.fields.scheduledAt = e && e._d || null}
                          initialValue={this.props.report && this.props.report.scheduledAt}/>
              </Form.Group>
            </Col>
            <Col xl={6}>
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label>Forced Service Status</Form.Label>
                <Select options={[{id: undefined, name: 'Keep Actual'}].concat(serviceStatusList)}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        isDisabled={this.props.disabled}
                        defaultValue={{id: undefined, name: 'Keep Actual'}}
                        onChange={(e) => this.state.fields.forcedStatus = e.id}/>
              </Form.Group>
            </Col>
          </Row>
          <Row style={{paddingTop: 10}}>
            <Col xl={12}>
              <Form.Group controlId="exampleForm.ControlSelect2">
                <Form.Label>Services</Form.Label>
                <Select options={this.state.services}
                        getOptionLabel={(option) => `${option.name} (${option.group.name})`}
                        getOptionValue={(option) => option.id}
                        defaultValue={this.props.report && this.props.report.services}
                        isDisabled={this.props.disabled}
                        isMulti
                        closeMenuOnSelect={false}
                        onChange={(e) => {
                          this.state.fields.services = e.map(elem => elem.id)
                        }}/>
              </Form.Group>
            </Col>
          </Row>
          <Row style={{paddingTop: 10}}>
            <Col xl={12}>
              <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Label>Description</Form.Label>
                <Form.Control as="textarea" rows={3} disabled={this.props.disabled}
                              defaultValue={this.props.report && this.props.report.description}
                              onChange={(e) => this.state.fields.description = e.target.value}/>
              </Form.Group>
            </Col>
          </Row>
          <Row style={{paddingTop: 10}}>
            <Col xl={12}>
              <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Label>Postmortem</Form.Label>
                <Form.Control as="textarea" rows={3} disabled={this.props.disabled}
                              defaultValue={this.props.report && this.props.report.postmortem}
                              onChange={(e) => this.state.fields.postmortem = e.target.value}/>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.props.close}>Close</Button>
          <Button type={"submit"} variant={this.props.actionType}>{this.props.title}</Button>
        </Modal.Footer>
      </Form>
    </Modal>);
  }
}