import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {Impact, impactColor} from "../components/reports/Impact";
import {MainHeader} from "../components/MainHeader";
import {Blank} from "../components/Blank";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSync} from "@fortawesome/free-solid-svg-icons";
import {BeatLoader} from "react-spinners";
import {PastIncidents} from "../components/PastIncidents";
import {MainFooter} from "../components/MainFooter";
import {Button} from "react-bootstrap";
import {Status} from "../components/reports/Status";

export class Report extends Component {
  constructor(props) {
    super(props);

    this.state = {
      report: null,
    }
  }

  async componentDidMount() {
    const req = await fetch(`/api/reports/${this.props.match.params.id}`);
    if (req.status === 401) return this.props.history.push('/logout')
    this.setState({report: await req.json()});
  }

  render() {
    return (
        <div className={"container"}>
          {!this.props.hide && <MainHeader history={this.props.history}/>}
          <Blank height={50}/>
          {this.state.report && <div className={'container'}>
            <div className={'row'}>
              <div className={'col-12'} style={{textAlign: 'center'}}>
                <h2 style={{color: impactColor[this.state.report.impact]}}>{this.state.report.name}</h2>
              </div>
            </div>
            <div className={'row'}>
              <div className={'col-12'} style={{textAlign: 'center'}}>
                <Impact impact={this.state.report.impact}/>
              </div>
            </div>
            <Blank height={20}/>
            <div className={'row'}>
              <div className={'col-12'} style={{textAlign: 'center'}}>
                <p className={'new-line'}>{this.state.report.description}</p>
              </div>
              <div className={'col-12'} style={{textAlign: 'center'}}>
                <span style={{color: 'gray'}}>{new Date(this.state.report.createdAt).toUTCString()}</span>
              </div>
            </div>
            <Blank height={20}/>
            <div className={'container'} style={{textAlign: 'center', maxWidth: "60%"}}>
              {this.state.report.events.reverse().map((event) =>
                  <div className={'row'}>
                    <div className={'col-xl-2'} style={{textAlign: 'left'}}>
                      <Status status={event.status}/>
                    </div>
                    <div className={'col-xl-10'} style={{textAlign: 'left', wordBreak: 'break-all'}}>
                      <p style={{marginBottom: 2}} className={'new-line'}>{event.message}</p>
                      <span style={{color: 'gray'}}>{new Date(event.updatedAt).toUTCString()}</span>
                    </div>
                  </div>
              )}
            </div>
          </div>}
          {!this.props.hide && <MainFooter/>}
        </div>
    );
  }
}