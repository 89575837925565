import React, {Component} from 'react';
import {BeatLoader} from "react-spinners";

export class Logout extends Component {
  componentDidMount() {
    localStorage.removeItem('authToken');
    this.props.history.push('/');
  }

  render() {
    return (<BeatLoader color={"#2a4982"} size={20}/>);
  }
}