import React, {Component} from 'react';
import {Impact, impactColor} from "./reports/Impact";

export class PastIncidents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      incidents: {}
    }
  }

  async componentDidMount() {
    const to = new Date();
    const from = new Date();
    from.setTime(from.getTime() - (24 * 60 * 60 * 1000 * 5));
    const req = await fetch(`/api/reports?from=${from.toISOString().split('T')[0]}T00:00:00Z&to=${to.toISOString().split('T')[0]}T23:59:59Z`);
    if (req.status === 401) return this.props.history.push('/logout')
    const reports = await req.json();
    const dates = this.getDates(from, to);

    reports.forEach(report => {
      const day = report.createdAt.split('T')[0];
      dates[day].push(report);
    })

    this.setState({incidents: dates})
  }


  getDates(startDate, stopDate) {
    const dates = {};
    let currentDate = startDate;

    while (currentDate <= stopDate) {
      const day = currentDate.toISOString().split('T')[0];
      dates[day] = [];
      currentDate.setTime(currentDate.getTime() + (24 * 60 * 60 * 1000));
    }

    return dates;
  }

  dateFormat(date) {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ]

    return `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
  }

  render() {
    return (
        <div className={"container"}>
          {Object.entries(this.state.incidents).reverse().map(([dateStr, incidents]) => {
            const date = new Date(dateStr);
            return (<div>
              <h5 className={"border-bottom"}><strong>{this.dateFormat(date)}</strong></h5>
              {!incidents.length && <h6 style={{color: 'gray'}}>No incidents reported.</h6>}
              {incidents.map(incident => (
                  <div style={{cursor: 'pointer', marginBottom: 10}}
                       onClick={() => this.props.history.push(`/reports/${incident.id}`)}>
                    <div className={'row'}>
                      <div className={'col-8'} style={{textAlign: 'left'}}>
                        <h5 style={{color: impactColor[incident.impact]}}><strong>{incident.name}</strong></h5>
                      </div>
                      <div className={'col-4'} style={{textAlign: 'right'}}>
                        <Impact impact={incident.impact}/>
                      </div>
                    </div>
                    <p style={{marginBottom: 2}} className={'new-line'}>{incident.description}</p>
                    <span style={{color: 'gray'}}>{new Date(incident.updatedAt).toUTCString()}</span>
                  </div>
              ))}
              <br/>
            </div>)
          })}
        </div>
    );
  }
}