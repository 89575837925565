import React, {Component} from 'react';
import DataTable from 'react-data-table-component';
import {Badge, Button, ButtonGroup, Row, Col, Modal, Form} from "react-bootstrap";
import {faEdit, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Select from "react-select";


export class Groups extends Component {
  constructor(props) {
    super(props);

    this.newGroup = this.newGroup.bind(this);
    this.editGroup = this.editGroup.bind(this);
    this.deleteGroup = this.deleteGroup.bind(this);

    this.state = {
      groups: [],
      modal: null
    }
  }

  async componentDidMount() {
    await this.loadGroups()
  }

  async loadGroups() {
    const request = await fetch('/api/groups/all', {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      }
    });

    if (request.status !== 200) return this.props.history.push('/admin/error');
    this.setState({groups: await request.json()})
  }

  newGroup() {
    const add = async (group) => {
      const resp = await fetch('/api/groups', {
        method: 'POST',
        body: JSON.stringify(group),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
      })

      if (resp.status !== 201) return;
      this.setState({modal: null});
      await this.loadGroups();
    }

    this.setState({
      modal: <GroupFormModal close={() => this.setState({modal: null})} actionType={"primary"} title={"Create group"}
                             passRequired action={add}

      />
    })
  }

  editGroup(group) {
    const edit = async (updatedGroup) => {
      const resp = await fetch(`/api/groups/${group.id}`, {
        method: 'PUT',
        body: JSON.stringify(updatedGroup),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
      })

      if (resp.status !== 200) return;
      this.setState({modal: null});
      await this.loadGroups();
    }

    this.setState({
      modal: <GroupFormModal close={() => this.setState({modal: null})} actionType={"primary"} title={"Edit group"}
                             group={group} action={edit}/>
    })
  }

  deleteGroup(group) {
    const remove = async () => {
      await fetch(`/api/groups/${group.id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
      })

      this.setState({modal: null});
      await this.loadGroups();
    }

    this.setState({
      modal: <GroupFormModal close={() => this.setState({modal: null})} actionType={"danger"} title={"Delete group"}
                             group={group} disabled={true} action={remove}/>
    })
  }

  render() {
    const columns = [
      {
        name: 'Id',
        selector: 'id',
        sortable: true,
      }, {
        name: 'Name',
        selector: 'name',
        sortable: true,
      }, {
        name: 'Order',
        selector: 'order',
        sortable: true,
      }, {
        name: 'Active',
        selector: 'active',
        cell: row => row.active ? <Badge pill variant="success">Active</Badge> :
            <Badge pill variant="danger">Inactive</Badge>
      }, {
        name: 'Visibility Role',
        selector: 'visibilityRoleId',
        cell: row => <Badge pill variant="info">{row.visibilityRole.name}</Badge>
      }, {
        name: 'Coalesced',
        selector: 'coalesced',
        cell: row => row.coalesced ? <Badge pill variant="success">Coalesced</Badge> :
            <Badge pill variant="danger">Non Coalesced</Badge>
      }, {
        title: 'Actions',
        cell: (row) => <ButtonGroup aria-label="Actions">
          <Button variant="primary" onClick={() => this.editGroup(row)}><FontAwesomeIcon icon={faEdit}
                                                                                         style={{color: 'white'}}/></Button>
          <Button variant="danger" onClick={() => this.deleteGroup(row)}><FontAwesomeIcon icon={faTrash}
                                                                                          style={{color: 'white'}}/></Button>
        </ButtonGroup>,
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
    ]

    return (
        <div className={"container-fluid"}>
          {this.state.modal}
          <Row style={{paddingTop: 10}}>
            <Col xl={8}>
              <h1>Groups List</h1>
            </Col>
            <Col xl={4}>
              <h1 style={{textAlign: 'right'}}><Button variant="primary" onClick={this.newGroup}>Add Group</Button></h1>
            </Col>
          </Row>
          <DataTable data={this.state.groups} columns={columns}/>
        </div>
    );
  }
}


class GroupFormModal extends Component {
  constructor(props) {
    super(props);
    this.actionHandler = this.actionHandler.bind(this);
    this.closeHandler = this.closeHandler.bind(this);
    this.state = {
      roles: [],
      active: this.props.group && this.props.group.active,
      coalesced: this.props.group && this.props.group.coalesced
    }
  }

  componentDidMount() {
    fetch('/api/roles', {headers: {'Authorization': `Bearer ${localStorage.getItem('authToken')}`}})
        .then(async (res) => {
          this.setState({roles: await res.json()})
        });
  }

  actionHandler(e) {
    e.preventDefault();
    console.log(this.state)
    this.props.action({
      ...this.state
    });
  }

  closeHandler(e) {
    e.preventDefault();
    this.props.close();
  }

  render() {
    const roleSelected = this.state.roles.filter(({id}) => id === (this.props.group && this.props.group.visibilityRoleId || 0))

    return <Modal show centered size="lg" onHide={this.props.close}>
      <Form onSubmit={this.actionHandler}>
        <Modal.Header closeButton>
          <Modal.Title>{this.props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row style={{paddingTop: 10}}>
            <Col xl={6}>
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label>Name</Form.Label>
                <Form.Control type="text" placeholder="Group Name"
                              defaultValue={this.props.group && this.props.group.name}
                              disabled={this.props.disabled} required
                              onChange={(e) => this.setState({name: e.target.value})}/>
              </Form.Group>
            </Col>
            <Col xl={4}>
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label>Group Order</Form.Label>
                <Form.Control type="number" min={0}
                              defaultValue={this.props.group && this.props.group.order || 0}
                              disabled={this.props.disabled}
                              required
                              onChange={(e) => this.setState({order: e.target.value})}/>
              </Form.Group>
            </Col>
            <Col xl={2}>
              <Form.Check type={'checkbox'}>
                <Form.Label>Active</Form.Label>
                <Form.Control type="checkbox" disabled={this.props.disabled} defaultChecked={this.state.active}
                              onChange={() => this.setState({active: !this.state.active})}/>
              </Form.Check>
            </Col>
          </Row>
          <Row style={{paddingTop: 10}}>
            <Col xl={5}>
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label>Group Role</Form.Label>
                {roleSelected.length &&
                    <Select options={this.state.roles}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            defaultValue={roleSelected[0]}
                            isDisabled={this.props.disabled}
                            onChange={(e) => this.setState({visibilityRoleId: e.id})}/>}
              </Form.Group>
            </Col>
            <Col xl={2}>
              <Form.Check type={'checkbox'}>
                <Form.Label>Coalesced</Form.Label>
                <Form.Control type="checkbox" disabled={this.props.disabled} defaultChecked={this.state.coalesced}
                              onChange={() => this.setState({coalesced: !this.state.coalesced})}/>
              </Form.Check>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.closeHandler}>Close</Button>
          <Button type={"submit"} variant={this.props.actionType}>{this.props.title}</Button>
        </Modal.Footer>
      </Form>
    </Modal>;
  }
}