import React, {Component} from 'react';
import {Impact, impactColor} from "./reports/Impact";

export class ActiveIncidents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIncidents: []
    }
  }

  async componentDidMount() {
    const req = await fetch(`/api/reports/active`);
    if (req.status === 401) return this.props.history.push('/logout')
    const reports = await req.json();

    this.setState({activeIncidents: reports})
  }

  getLatestEvent(report) {
    let latestData = {description: null, updatedAt: null}

    if (report.events.length > 1) {
      let latestEvent = report.events[report.events.length - 1];
      latestData.description = latestEvent.message;
      latestData.updatedAt = latestEvent.updatedAt;

      return latestData;
    }

    latestData.description = report.description;
    latestData.updatedAt = report.updatedAt;

    return latestData;
  }

  render() {
    return (
        <div className={"container"}>
          {this.state.activeIncidents.map(incident => {
            let latestData = this.getLatestEvent(incident)
            return (
                <div style={{cursor: 'pointer', marginBottom: 10}}
                     onClick={() => this.props.history.push(`/reports/${incident.id}`)}>
                  <div className={'row'}>
                    <div className={'col-8'} style={{textAlign: 'left'}}>
                      <h5 style={{color: impactColor[incident.impact]}}><strong>{incident.name}</strong></h5>
                    </div>
                    <div className={'col-4'} style={{textAlign: 'right'}}>
                      <Impact impact={incident.impact}/>
                    </div>
                  </div>
                  <p style={{marginBottom: 2}} className={'new-line'}>{latestData.description}</p>
                  <span style={{color: 'gray'}}>{new Date(latestData.updatedAt).toUTCString()}</span>
                  <hr/>
                </div>
            )
          })}
        </div>
    );
  }
}