import React, {Component} from 'react';
import {List} from "../../components/reports/List";
import {Show} from "../../components/reports/Show";

export class Reports extends Component {
  async removeReport(id) {
    await fetch(`/api/reports/${id}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      },
    })

    this.props.history.push('/admin/reports');
  }

  render() {
    if (this.props.match.params.id) return <Show {...this.props} removeReport={this.removeReport}/>
    return <List {...this.props} removeReport={this.removeReport}/>;
  }
}