import React, {Component} from 'react';
import {Badge, Button, ButtonGroup, Col, Dropdown, DropdownButton, Row} from "react-bootstrap";
import {serviceStatus} from "../Service";
import DataTable from "react-data-table-component";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faTools, faTrash} from "@fortawesome/free-solid-svg-icons";

export const impactList = [
  {id: 0, name: 'None'},
  {id: 1, name: 'Minor'},
  {id: 2, name: 'Major'},
  {id: 3, name: 'Critical'},
  {id: 4, name: 'Maintenance'},
];

export const impactColor = {
  0: 'green',
  1: 'orange',
  2: 'red',
  3: 'purple',
  4: '#3498DB',
}

export class Impact extends Component {
  render() {
    switch (this.props.impact) {
      case 1:
        return <Badge pill variant={"secondary"} style={{background: impactColor[1]}}>Minor</Badge>
      case 2:
        return <Badge pill variant={"secondary"} style={{background: impactColor[2]}}>Major</Badge>
      case 3:
        return <Badge pill variant={"secondary"} style={{background: impactColor[3]}}>Critical</Badge>
      case 4:
        return <Badge pill variant={"secondary"} style={{background: impactColor[4]}}>Maintenance</Badge>
      default:
        return <Badge pill variant={"secondary"} style={{background: impactColor[0]}}>None</Badge>
    }
  }
}