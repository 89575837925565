import React, {Component} from 'react';
import {Button, Col, Form, Modal, Row} from "react-bootstrap";


export class KongIntegration extends Component {
  constructor(props) {
    super(props);
    this.upsertHandler = this.upsertHandler.bind(this);
    this.closeHandler = this.closeHandler.bind(this);
    this.removeHandler = this.removeHandler.bind(this);

    this.state = {
      data: null,
      values: {active: false}
    }
  }

  componentDidMount() {
    fetch(`/api/services/${this.props.serviceId}/kong`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      }
    }).then(async response => {
      if (response.status !== 200) return
      const data = await response.json()
      this.setState({data: data, values: {active: data.active, service: data.service}})
    })
  }

  async upsertHandler(e) {
    e.preventDefault();
    console.log(this.state.values)
    const resp = await fetch(`/api/services/${this.props.serviceId}/kong`, {
      method: 'PUT',
      body: JSON.stringify(this.state.values),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      },
    })

    if (resp.status !== 200) return;
    this.setState({modal: null});
    this.props.close()
  }

  async removeHandler(e) {
    e.preventDefault();
    await fetch(`/api/services/${this.props.serviceId}/kong`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      },
    })
    this.props.close();
  }

  closeHandler(e) {
    e.preventDefault();
    this.props.close();
  }

  render() {
    return (<Modal show centered size="lg" onHide={this.props.close}>
      <Form onSubmit={this.upsertHandler}>
        <Modal.Header closeButton>
          <Modal.Title>Manage Kong integration in {this.props.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row style={{paddingTop: 10}}>
            <Col xl={8}>
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label>Server</Form.Label>
                <Form.Control type="text" placeholder="Kong Service Identification"
                              defaultValue={this.state.data && this.state.data.service}
                              disabled={this.props.disabled} required
                              onChange={(e) => this.setState({
                                values: {
                                  ...this.state.values,
                                  service: e.target.value
                                }
                              })}/>
              </Form.Group>
            </Col>
            <Col xl={4}>
              <Form.Check type={'checkbox'}>
                <Form.Label>Active</Form.Label>
                <input type="checkbox" disabled={this.props.disabled}
                       className={"form-control"}
                       checked={this.state.values.active}
                       onChange={(e) => this.setState({
                         values: {
                           ...this.state.values,
                           active: !this.state.values.active
                         }
                       })
                       }/>
              </Form.Check>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.closeHandler}>Close</Button>
          <Button type={"submit"} variant={"primary"}>{this.state.data ? "Update" : "Create"}</Button>
          <Button variant="danger" onClick={this.removeHandler}>Delete</Button>
        </Modal.Footer>
      </Form>
    </Modal>);
  }
}