import React, {Component} from 'react';
import DataTable from 'react-data-table-component';
import {Badge, Button, ButtonGroup, Row, Col, Modal, Form, DropdownButton, Dropdown} from "react-bootstrap";
import {faEdit, faTrash, faTools} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {serviceStatus, statusList} from "../../components/Service";
import {FsdIntegration} from "../../components/integrations/FsdIntegration";
import {KongIntegration} from "../../components/integrations/KongIntegration";
import {NodeExporterIntegration} from "../../components/integrations/NodeExporterIntegration";
import {PingIntegration} from "../../components/integrations/PingIntegration";
import {WorldServerIntegration} from "../../components/integrations/WorldServerIntegration";
import Select from "react-select";
import {CurlIntegration} from "../../components/integrations/CurlIntegration";


const integrations = {
  'FSD Integration': FsdIntegration,
  'Kong Integration': KongIntegration,
  'NodeExporter Integration': NodeExporterIntegration,
  'Ping Integration': PingIntegration,
  'Curl Integration': CurlIntegration,
  'World Server Integration': WorldServerIntegration
}

export class Services extends Component {
  constructor(props) {
    super(props);

    this.newService = this.newService.bind(this);
    this.editService = this.editService.bind(this);
    this.deleteService = this.deleteService.bind(this);
    this.tableActions = this.tableActions.bind(this);

    this.state = {
      services: [],
      modal: null
    }
  }

  async componentDidMount() {
    await this.loadServices()
  }

  async loadServices() {
    const request = await fetch('/api/services', {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      }
    });

    if (request.status !== 200) return this.props.history.push('/admin/error');
    this.setState({services: await request.json()})
  }

  newService() {
    const add = async (service) => {
      const resp = await fetch('/api/services', {
        method: 'POST',
        body: JSON.stringify(service),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
      })

      if (resp.status !== 201) return;
      this.setState({modal: null});
      await this.loadServices();
    }

    this.setState({
      modal: <ServiceFormModal close={() => this.setState({modal: null})} actionType={"primary"}
                               title={"Create service"}
                               passRequired action={add}

      />
    })
  }

  editService(service) {
    const edit = async (updatedService) => {
      const resp = await fetch(`/api/services/${service.id}`, {
        method: 'PUT',
        body: JSON.stringify(updatedService),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
      })

      if (resp.status !== 200) return;
      this.setState({modal: null});
      await this.loadServices();
    }

    this.setState({
      modal: <ServiceFormModal close={() => this.setState({modal: null})} actionType={"primary"} title={"Edit service"}
                               service={service} action={edit}/>
    })
  }

  deleteService(service) {
    const remove = async () => {
      await fetch(`/api/services/${service.id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
      })

      this.setState({modal: null});
      await this.loadServices();
    }

    this.setState({
      modal: <ServiceFormModal close={() => this.setState({modal: null})} actionType={"danger"} title={"Delete service"}
                               service={service} disabled={true} action={remove}/>
    })
  }

  tableActions(row) {
    return <ButtonGroup aria-label="Actions">
      <Button variant="primary" onClick={() => this.editService(row)}>
        <FontAwesomeIcon icon={faEdit} style={{color: 'white'}}/>
      </Button>
      <DropdownButton id="dropdown-basic-button" variant={'secondary'} className={"dropdown-toggle-no-arrow"}
                      title={<FontAwesomeIcon icon={faTools} style={{color: 'white'}}/>}>
        {Object.entries(integrations).map(([name, Integration]) => {
          return <Dropdown.Item
              onClick={() => this.setState({
                modal: <Integration close={async () => {
                  this.setState({modal: null})
                  await this.loadServices();
                }} serviceId={row.id} name={row.name}/>
              })}>{name}</Dropdown.Item>
        })}
      </DropdownButton>
      <Button variant="danger" onClick={() => this.deleteService(row)}>
        <FontAwesomeIcon icon={faTrash} style={{color: 'white'}}/>
      </Button>
    </ButtonGroup>
  }

  tableIntegrations(row) {
    return <div style={{textAlign: "center", position: "relative", padding: 10}}>
      {['fsdService', 'kongService', 'nodeExporterService', 'pingService', 'curlService', 'worldServerService'].map(service => {
        if (!row[service]) return <><Badge pill variant={"secondary"}>{service}</Badge><br/></>
        if (row[service].active) return <><Badge pill variant={"success"}>{service}</Badge><br/></>
        return <><Badge pill variant={"danger"}>{service}</Badge><br/></>
      })}
    </div>
  }

  render() {
    const columns = [
      {
        name: 'Name',
        selector: 'name',
        sortable: true,
      }, {
        name: 'Group',
        selector: 'groupId',
        cell: row => <Badge pill variant="info">{row.group.name}</Badge>,
        minWidth: "12%"
      }, {
        name: 'Order',
        selector: 'order',
        sortable: true,
      }, {
        name: 'Active',
        selector: 'active',
        cell: row => row.active ? <Badge pill variant="success">Active</Badge> :
            <Badge pill variant="danger">Inactive</Badge>
      }, {
        name: 'Visibility Role',
        selector: 'visibilityRoleId',
        cell: row => <Badge pill variant="info">{row.visibilityRole.name}</Badge>
      }, {
        name: 'Status',
        selector: 'status',
        cell: row => serviceStatus(row.status),
        minWidth: "12%"
      }, {
        name: 'Forced Status',
        selector: 'forcedStatus',
        cell: row => serviceStatus(row.forcedStatus),
        minWidth: "12%"
      }, {
        name: 'Integrations',
        cell: this.tableIntegrations,
        style: {display: "flex"},
        minWidth: "15%"
      }, {
        title: 'Actions',
        cell: this.tableActions,
        ignoreRowClick: true,
        button: false,
        minWidth: "15%"
      },
    ]

    return (
        <div className={"container-fluid"}>
          {this.state.modal}
          <Row style={{paddingTop: 10}}>
            <Col xl={8}>
              <h1>Services List</h1>
            </Col>
            <Col xl={4}>
              <h1 style={{textAlign: 'right'}}><Button variant="primary" onClick={this.newService}>Add Service</Button>
              </h1>
            </Col>
          </Row>
          <DataTable data={this.state.services} columns={columns} overflowY={true} style={{height: '100vh'}}/>
        </div>
    );
  }
}


class ServiceFormModal extends Component {
  constructor(props) {
    super(props);
    this.actionHandler = this.actionHandler.bind(this);
    this.closeHandler = this.closeHandler.bind(this);
    this.state = {
      roles: [],
      groups: [],
      active: this.props.service && this.props.service.active,
    }
  }

  componentDidMount() {
    fetch('/api/roles', {headers: {'Authorization': `Bearer ${localStorage.getItem('authToken')}`}})
        .then(async (res) => {
          this.setState({roles: await res.json()})
        });

    fetch('/api/groups/all', {headers: {'Authorization': `Bearer ${localStorage.getItem('authToken')}`}})
        .then(async (res) => {
          this.setState({groups: await res.json()})
        });
  }

  actionHandler(e) {
    e.preventDefault();
    this.props.action({
      ...this.state,
      groupId: this.state.groupId || (this.props.service && this.props.service.groupId) || this.state.groups[0].id,
    });
  }

  closeHandler(e) {
    e.preventDefault();
    this.props.close();
  }

  render() {
    const roleSelected = this.state.roles.filter(({id}) => id === (this.props.service && this.props.service.visibilityRoleId || 0))
    const groupSelected = this.state.groups.filter(({id}) => id === (this.props.service && this.props.service.groupId || this.state.groups[0].id))
    const statusSelected = statusList.filter(({id}) => id === (this.props.service && this.props.service.forcedStatus || null))

    return (<Modal show centered size="lg" onHide={this.props.close}>
      <Form onSubmit={this.actionHandler}>
        <Modal.Header closeButton>
          <Modal.Title>{this.props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row style={{paddingTop: 10}}>
            <Col xl={6}>
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label>Name</Form.Label>
                <Form.Control type="text" placeholder="Service Name"
                              defaultValue={this.props.service && this.props.service.name}
                              disabled={this.props.disabled} required
                              onChange={(e) => this.setState({name: e.target.value})}/>
              </Form.Group>
            </Col>
            <Col xl={4}>
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label>Service Order</Form.Label>
                <Form.Control type="number" min={0}
                              defaultValue={this.props.service && this.props.service.order || 0}
                              disabled={this.props.disabled}
                              required
                              onChange={(e) => this.setState({order: e.target.value})}/>
              </Form.Group>
            </Col>
            <Col xl={2}>
              <Form.Check type={'checkbox'}>
                <Form.Label>Active</Form.Label>
                <Form.Control type="checkbox" disabled={this.props.disabled} defaultChecked={this.state.active}
                              onChange={() => this.setState({active: !this.state.active})}/>
              </Form.Check>
            </Col>
          </Row>
          <Row style={{paddingTop: 10}}>
            <Col xl={4}>
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label>Service Role</Form.Label>
                {roleSelected.length &&
                    <Select options={this.state.roles}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            defaultValue={roleSelected[0]}
                            isDisabled={this.props.disabled}
                            onChange={(e) => this.setState({visibilityRoleId: e.id})}/>}
              </Form.Group>
            </Col>
            <Col xl={4}>
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label>Service Group</Form.Label>
                {groupSelected.length &&
                    <Select options={this.state.groups}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            defaultValue={groupSelected[0]}
                            isDisabled={this.props.disabled}
                            onChange={(e) => this.setState({groupId: e.id})}/>}
              </Form.Group>
            </Col>
            <Col xl={4}>
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label>Forced Status</Form.Label>
                {statusSelected.length &&
                    <Select options={statusList}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            defaultValue={statusSelected[0]}
                            isDisabled={this.props.disabled}
                            onChange={(e) => this.setState({forcedStatus: e.id})}/>}
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.closeHandler}>Close</Button>
          <Button type={"submit"} variant={this.props.actionType}>{this.props.title}</Button>
        </Modal.Footer>
      </Form>
    </Modal>);
  }
}
