import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {Impact, impactColor} from "../components/reports/Impact";
import {MainHeader} from "../components/MainHeader";
import {Blank} from "../components/Blank";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSync} from "@fortawesome/free-solid-svg-icons";
import {BeatLoader} from "react-spinners";
import {PastIncidents} from "../components/PastIncidents";
import {MainFooter} from "../components/MainFooter";
import {Button} from "react-bootstrap";

export class IncidentsHistory extends Component {
  constructor(props) {
    super(props);

    const page = parseInt(new URLSearchParams(this.props.location.search).get("page") || 1) - 1

    const to = new Date();
    to.setMonth(to.getMonth() - (3 * page) + 1)
    to.setDate(0);

    const from = new Date();
    from.setMonth(from.getMonth() - (3 * page) - 2)
    from.setDate(1);

    this.state = {
      incidents: {},
      page: page + 1,
      from: from,
      to: to
    }
  }

  async componentDidMount() {
    const to = this.state.to;
    const from = this.state.from;

    const req = await fetch(`/api/reports?from=${from.toISOString().split('T')[0]}T00:00:00Z&to=${to.toISOString().split('T')[0]}T23:59:59Z`);
    if (req.status === 401) return this.props.history.push('/logout')
    const reports = await req.json();
    const dates = this.getMonths(from, to);

    reports.forEach(report => {
      const currentDate = new Date(report.createdAt);
      const month = `${currentDate.getFullYear()}-${("0" + (currentDate.getMonth() + 1)).slice(-2)}-01`;
      dates[month].push(report);
    })

    this.setState({incidents: dates});
  }

  getMonths(startDate, stopDate) {
    const months = {};
    let currentDate = new Date(startDate);

    while (currentDate <= stopDate) {
      const month = `${currentDate.getFullYear()}-${("0" + (currentDate.getMonth() + 1)).slice(-2)}-01`;
      months[month] = [];
      currentDate.setMonth(currentDate.getMonth() + 1);
    }

    return months;
  }

  dateFormat(date) {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ]

    return `${months[date.getMonth()]} ${date.getFullYear()}`;
  }

  setPage(page) {
    if (page === 0) return;

    this.props.history.push(`/history?page=${page}`)
    window.location.reload();
  }

  render() {
    return (
      <div className={"container"}>
        {!this.props.hide && <MainHeader history={this.props.history}/>}
        <Blank height={50}/>
        <div className={"row"}>
          <div className={"col-xl-4"} style={{textAlign: 'left'}}><h2><strong>Incident History</strong></h2></div>
          <div className={"col-xl-8"} style={{textAlign: 'right', display: 'inline-block'}}>
            <h2>
              <a className={"btn btn-secondary"}
                 onClick={() => this.setPage(this.state.page + 1)}>
                <strong>&lt;</strong>
              </a>
              <a className={"btn"}>
                {this.dateFormat(this.state.from)} to {this.dateFormat(this.state.to)}
              </a>
              <a className={"btn btn-secondary"}
                 onClick={() => this.setPage(this.state.page - 1)}>
                <strong>&gt;</strong>
              </a>
            </h2>
          </div>
        </div>
        <Blank height={30}/>
        <div className={"container"}>
          {Object.entries(this.state.incidents).reverse().map(([dateStr, incidents]) => {
            const date = new Date(dateStr);
            return (<div>
              <h3 className={"border-bottom"}><strong>{this.dateFormat(date)}</strong></h3>
              {!incidents.length && <h6 style={{color: 'gray'}}>No incidents reported for this month.</h6>}
              {incidents.map(incident => (
                <div style={{cursor: 'pointer', marginBottom: 10}}
                     onClick={() => this.props.history.push(`/reports/${incident.id}`)}>
                  <div className={'row'}>
                    <div className={'col-8'} style={{textAlign: 'left'}}>
                      <h4 style={{color: impactColor[incident.impact]}}><strong>{incident.name}</strong></h4>
                    </div>
                    <div className={'col-4'} style={{textAlign: 'right'}}>
                      <Impact impact={incident.impact}/>
                    </div>
                  </div>
                  <p style={{marginBottom: 2}}>{incident.description}</p>
                  <span style={{color: 'gray'}}>{new Date(incident.updatedAt).toUTCString()}</span>
                </div>
              ))}
              <br/>
            </div>)
          })}
        </div>
        {!this.props.hide && <MainFooter/>}
      </div>
    );
  }
}