import React, {Component} from 'react';
import {DropdownButton, Dropdown} from 'react-bootstrap';

export class MainHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {showSubscriptions: true}
  }

  openInNewTab(href) {
    Object.assign(document.createElement('a'), {
      target: '_blank',
      href,
    }).click();
  }

  render() {
    return (
        <div className={"container"}>
          <div className={"row align-items-center"} style={{margin: 20}}>
            <div className={"col-xl-2"} style={{textAlign: "center", cursor: 'pointer'}}
                 onClick={() => this.props.history.push('/')}>
              <img src={"https://static.ivao.aero/img/logos/logo.svg"} alt={"IVAO Logo"} style={{height: 100}}/>
            </div>
            <div className={"col-xl-8"} style={{textAlign: "center"}}/>
            <div className={"col-xl-2"} style={{textAlign: "center"}}>
              <DropdownButton id="dropdown-basic-button" title="Subscribe to updates"
                              className={"dropdown-toggle-no-arrow"}>
                <Dropdown.Item onClick={() => this.openInNewTab("https://twitter.com/IVAOlogistics")}>
                  Twitter
                </Dropdown.Item>
                <Dropdown.Item onClick={() => this.openInNewTab("https://www.ivao.aero/Content/About/Contact.aspx")}>
                  Support Website
                </Dropdown.Item>
                <Dropdown.Item onClick={() => this.openInNewTab("https://heartbeat.ivao.aero/api/reports/rss")}>
                  RSS Feed
                </Dropdown.Item>
              </DropdownButton>
            </div>
          </div>
        </div>
    );
  }
}