import React, {Component} from 'react';
import DataTable from 'react-data-table-component';
import {Badge, Button, ButtonGroup, Row, Col, Modal, Form} from "react-bootstrap";
import {faEdit, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Select from "react-select";


export class Users extends Component {
  constructor(props) {
    super(props);

    this.newUser = this.newUser.bind(this);
    this.editUser = this.editUser.bind(this);
    this.deleteUser = this.deleteUser.bind(this);

    this.state = {
      users: [],
      modal: null
    }
  }

  async componentDidMount() {
    await this.loadUsers()
  }

  async loadUsers() {
    const request = await fetch('/api/users', {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      }
    });

    if (request.status !== 200) return this.props.history.push('/admin/error');
    this.setState({users: await request.json()})
  }

  newUser() {
    const add = async (user) => {
      const resp = await fetch('/api/users', {
        method: 'POST',
        body: JSON.stringify(user),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
      })

      if (resp.status !== 201) return;
      this.setState({modal: null});
      await this.loadUsers();
    }

    this.setState({
      modal: <UserFormModal close={() => this.setState({modal: null})} actionType={"primary"} title={"Create user"}
                            passRequired action={add}

      />
    })
  }

  editUser(user) {
    const edit = async (updatedUser) => {
      const resp = await fetch(`/api/users/${user.id}`, {
        method: 'PUT',
        body: JSON.stringify(updatedUser),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
      })

      if (resp.status !== 200) return;
      this.setState({modal: null});
      await this.loadUsers();
    }

    this.setState({
      modal: <UserFormModal close={() => this.setState({modal: null})} actionType={"primary"} title={"Edit user"}
                            user={user} action={edit}/>
    })
  }

  deleteUser(user) {
    const remove = async () => {
      await fetch(`/api/users/${user.id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
      })

      this.setState({modal: null});
      await this.loadUsers();
    }

    this.setState({
      modal: <UserFormModal close={() => this.setState({modal: null})} actionType={"danger"} title={"Delete user"}
                            user={user} disabled={true} action={remove}/>
    })
  }

  render() {
    const columns = [
      {
        name: 'Id',
        selector: 'id',
        sortable: true,
      }, {
        name: 'Name',
        selector: 'name',
        sortable: true,
      }, {
        name: 'Mail',
        selector: 'mail',
      }, {
        name: 'Active',
        selector: 'active',
        cell: row => row.active ? <Badge pill variant="success">Active</Badge> :
            <Badge pill variant="danger">Inactive</Badge>
      }, {
        name: 'Role',
        selector: 'roleId',
        cell: row => <Badge pill variant="info">{row.role.name}</Badge>
      }, {
        title: 'Actions',
        cell: (row) => <ButtonGroup aria-label="Actions">
          <Button variant="primary" onClick={() => this.editUser(row)}><FontAwesomeIcon icon={faEdit}
                                                                                        style={{color: 'white'}}/></Button>
          <Button variant="danger" onClick={() => this.deleteUser(row)}><FontAwesomeIcon icon={faTrash}
                                                                                         style={{color: 'white'}}/></Button>
        </ButtonGroup>,
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
    ]

    return (
        <div className={"container-fluid"}>
          {this.state.modal}
          <Row style={{paddingTop: 10}}>
            <Col xl={8}>
              <h1>Users List</h1>
            </Col>
            <Col xl={4}>
              <h1 style={{textAlign: 'right'}}><Button variant="primary" onClick={this.newUser}>Add User</Button></h1>
            </Col>
          </Row>
          <DataTable data={this.state.users} columns={columns}/>
        </div>
    );
  }
}


class UserFormModal extends Component {
  constructor(props) {
    super(props);
    this.actionHandler = this.actionHandler.bind(this);
    this.closeHandler = this.closeHandler.bind(this);
    this.state = {roles: [], active: this.props.user && this.props.user.active}
  }

  componentDidMount() {
    fetch('/api/roles', {headers: {'Authorization': `Bearer ${localStorage.getItem('authToken')}`}})
        .then(async (res) => {
          this.setState({roles: await res.json()})
        });
  }

  actionHandler(e) {
    e.preventDefault();
    this.props.action({
      ...this.state,
      password: this.state.password === "" ? null : this.state.password,
    });
  }

  closeHandler(e) {
    e.preventDefault();
    this.props.close();
  }

  render() {
    const roleSelected = this.state.roles.filter(({id}) => id === (this.props.user && this.props.user.roleId || 0))

    return (<Modal show centered size="lg" onHide={this.props.close}>
      <Form onSubmit={this.actionHandler}>
        <Modal.Header closeButton>
          <Modal.Title>{this.props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row style={{paddingTop: 10}}>
            <Col xl={6}>
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label>Name</Form.Label>
                <Form.Control type="text" placeholder="Personal name"
                              defaultValue={this.props.user && this.props.user.name}
                              disabled={this.props.disabled} required
                              onChange={(e) => this.setState({name: e.target.value})}/>
              </Form.Group>
            </Col>
            <Col xl={6}>
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label>Email address</Form.Label>
                <Form.Control type="email" placeholder="name@ivao.aero"
                              defaultValue={this.props.user && this.props.user.mail} disabled={this.props.disabled}
                              required
                              onChange={(e) => this.setState({mail: e.target.value})}/>
              </Form.Group>
            </Col>
          </Row>
          <Row style={{paddingTop: 10}}>
            <Col xl={5}>
              <Form.Group controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" placeholder="Password" disabled={this.props.disabled}
                              required={this.props.passRequired}
                              onChange={(e) => this.setState({password: e.target.value})}/>
              </Form.Group>
            </Col>
            <Col xl={5}>
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label>User Role</Form.Label>
                {roleSelected.length &&
                    <Select options={this.state.roles}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            defaultValue={roleSelected[0]}
                            isDisabled={this.props.disabled}
                            onChange={(e) => this.setState({roleId: e.id})}/>}
              </Form.Group>
            </Col>
            <Col xl={2}>
              <Form.Check type={'checkbox'}>
                <Form.Label>Active</Form.Label>
                <Form.Control type="checkbox" disabled={this.props.disabled} defaultChecked={this.state.active}
                              onChange={() => this.setState({active: !this.state.active})}/>
              </Form.Check>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.closeHandler}>Close</Button>
          <Button type={"submit"} variant={this.props.actionType}>{this.props.title}</Button>
        </Modal.Footer>
      </Form>
    </Modal>);
  }
}