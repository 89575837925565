import React, {Component} from 'react';
import {Badge} from "react-bootstrap";

export const statusList = [

  // Incident
  {id: 0, name: 'Created'},
  {id: null, name: 'Incident Status', disabled: true},
  {id: 1, name: 'Investigating'},
  {id: 2, name: 'Identified'},
  {id: 3, name: 'Monitoring'},
  {id: 4, name: 'Resolved'},
  // Maintenance
  {id: null, name: 'Maintenance Status', disabled: true},
  {id: 11, name: 'In Progress'},
  {id: 12, name: 'Verifying'},
  {id: 13, name: 'Completed'},
];


export class Status extends Component {
  render() {
    switch (this.props.status) {
        // Incident
      case 1:
        return <Badge pill variant={"warning"}>Investigating</Badge>
      case 2:
        return <Badge pill variant={"info"}>Identified</Badge>
      case 3:
        return <Badge pill variant={"primary"}>Monitoring</Badge>
      case 4:
        return <Badge pill variant={"primary"}>Resolved</Badge>
        // Maintenance
      case 11:
        return <Badge pill variant={"warning"}>In Progress</Badge>
      case 12:
        return <Badge pill variant={"info"}>Verifying</Badge>
      case 13:
        return <Badge pill variant={"primary"}>Completed</Badge>
      default:
        return <Badge pill variant={"secondary"}>Created</Badge>
    }
  }
}