import React, {Component} from 'react';
import {Link} from "react-router-dom";

export class MainFooter extends Component {
  render() {
    return (
        <div className="bg-white text-center p-3 w-100">
          <footer className="page-footer font-small blue">
            <div className="footer-copyright text-center py-3">
              © {new Date().getFullYear()} <b>International Virtual Aviation Organisation</b>. All Rights Reserved. <br/>
              <a href="https://wiki.ivao.aero/home/ivao/privacypolicy" target="_blank">Privacy Policy</a> | <a> </a>
              <a href="https://wiki.ivao.aero/home/ivao/terms-of-use" target="_blank">Terms of Use</a> | <a> </a>
              {!localStorage.getItem('authToken') && <Link to={"/login"}> Login</Link>}
              {localStorage.getItem('authToken') && <Link to={"/admin"}> Administration</Link>}
            </div>
          </footer>
        </div>
    );
  }
}